


















































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import Component from "vue-class-component";
import Icon from "@/components/Icon.vue";
import { store } from "@/store";
@Component({
    name: "LoreButton",
	components: {
		Icon,
	},
    props: {
        titleKey: String,
        resultKey: String,
        bodyKey: String,
        successKey: String,
        failureKey: String,
        isSkillCheck: Boolean,
        isChoice: Boolean,
        isGameplay: Boolean,
        effects: [Object, Array],
        conditions: Array,
    },
    computed: {
        ...mapState({
            hiddenInfo: (state: any) => state.SettingsModule.hiddenInfo,
        }),
    },
})
export default class LoreButton extends Vue {
    isSelected = false;

    onSelect() {
        if (this.getPlayerArchetypeIsAllowed()) {
            this.$emit("click");
        }
    }

    getPlayerArchetypeIsAllowed() {
        if (this.$props.conditions) {
            return (
                this.$props.conditions.indexOf(
                    store.getters.getSelectedCharacterArchetype
                ) > -1
            );
        }
        return true;
    }
}
