















import Vue from "vue";
import Component from "vue-class-component";
import { store } from "@/store";
@Component({
    name: "Icon",
    props: {
        amount: [String, Number],
        color: String,
        icon: String,
        size: Number,
		isColumn: Boolean
    }
})


export default class Icon extends Vue {
    isSelected = false;

    onSelect() {
        if (this.getPlayerArchetypeIsAllowed()) {
            this.$emit("click");
        }
    }

    getPlayerArchetypeIsAllowed() {
        if (this.$props.conditions) {
            return (
                this.$props.conditions.indexOf(
                    store.getters.getSelectedCharacterArchetype
                ) > -1
            );
        }
        return true;
    }
}

