










































import { DATABASE_CLEAR, SETTINGS_TOGGLE_HIDDEN_INFO, SETTINGS_TOGGLE_STRICT_RULES } from '@/helpers/consts';
import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
@Component({
  name: "SettingsView",
  computed: {
	  ...mapState({
		  strictRules: (state: any) => state.SettingsModule.strictRules,
		  hiddenInfo: (state: any) => state.SettingsModule.hiddenInfo,
	  })
  },
  watch: {
	  strictRules: function(val)
	  {
		  // @ts-ignore
		  this.proxyStrictRules = val;
	  },
	  hiddenInfo: function(val)
	  {
		  // @ts-ignore
		  this.proxyHiddenInfo = val;
	  }
  }
})

export default class SettingsView extends Vue {
	proxyHiddenInfo: boolean = this.$store.state.SettingsModule.hiddenInfo;
	proxyStrictRules: boolean = this.$store.state.SettingsModule.strictRules;

	mounted()
	{
		this.proxyHiddenInfo = this.$store.state.SettingsModule.hiddenInfo;
		this.proxyStrictRules = this.$store.state.SettingsModule.strictRules;
	}

	ClearGame() {
		this.$store.dispatch(DATABASE_CLEAR);
		this.$buefy.dialog.alert('<span class="mr_heading">The game has been cleared.</span>')

	}

	ToggleStrictRules() {
		this.$store.dispatch(SETTINGS_TOGGLE_STRICT_RULES);
	}

	ToggleHiddenInfo() {
		this.$store.dispatch(SETTINGS_TOGGLE_HIDDEN_INFO);
	}
}
