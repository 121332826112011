


































import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import DungeonGridArea from "@/components/DungeonGridArea.vue";
import ValueTracker from "@/components/ValueTracker.vue";
import PotionToggle from "@/components/PotionToggle.vue";
import EffectToggle from "@/components/EffectToggle.vue";
import VueI18n from "vue-i18n";

@Component({
    name: "DungeonMatView",
	components: {
		DungeonGridArea,
		ValueTracker,
        PotionToggle,
        EffectToggle,
	},
	computed: {
		...mapState({
			dungeonArea: (state: any) => state.PlayerModule.dungeonArea,
            monsterHP: (state: any) => state.PlayerModule.mhp,
            playerHP: (state: any) => state.PlayerModule.hp,
            playerXP: (state: any) => state.PlayerModule.xp,
            playerAP: (state: any) => state.PlayerModule.ap,
            playerFP: (state: any) => state.PlayerModule.fp,
            playerGP: (state: any) => state.PlayerModule.gp,
            playerCursed: (state: any) => state.PlayerModule.isCursed,
            playerPoisoned: (state: any) => state.PlayerModule.isPoisoned,
            playerBlind: (state: any) => state.PlayerModule.isBlind,

            hasPotionFire: (state: any) =>
                state.PlayerModule.potions.indexOf("fire") > -1,
            hasPotionFrost: (state: any) =>
                state.PlayerModule.potions.indexOf("frost") > -1,
            hasPotionPoison: (state: any) =>
                state.PlayerModule.potions.indexOf("poison") > -1,
            hasPotionHealing: (state: any) =>
                state.PlayerModule.potions.indexOf("healing") > -1,
            hasPotionHolywater: (state: any) =>
                state.PlayerModule.potions.indexOf("holywater") > -1,
            hasPotionPerception: (state: any) =>
                state.PlayerModule.potions.indexOf("perception") > -1,

            playerLevel: (state: any) => state.PlayerModule.level,
		}),
	}
})
export default class DungeonMatView extends Vue {

	mats = {
		"dungeon": {
			name: "dungeon.mat.dungeon",
			floors: [
				{"floor": 1, "areas": [1, 2], "boss": 2},
				{"floor": 2, "areas": [3, 4], "boss": 4},
				{"floor": 3, "areas": [5, 6, 7], "boss": 7},
				{"floor": 4, "areas": [8, 9, 10], "boss":10},
			]
		},
		"tower": {
			name: "dungeon.mat.tower",
			floors:	[
				{"floor": 4, "areas": [0, 9, 10], "boss":10},
				{"floor": 3, "areas": [7, 8], "boss": 8},
				{"floor": 2, "areas": [6, 5, 4], "boss": 5},
				{"floor": 1, "areas": [1, 2, 3], "boss": 3},
			]
		},
		// "forest": {
		// 	name: "The Forest",
		// 	floors:	[
		// 		{"floor": 1, "areas": [1, 2]},
		// 		{"floor": 2, "areas": [3, 4, 5, 6], "boss": 3},
		// 		{"floor": 3, "areas": [7], "boss": 7},
		// 		{"floor": 4, "areas": [8, 9, 10], "boss":10},
		// 	]
		// },
	};

	selectedMat = this.mats["dungeon"];

}
