














































import { DUNGEON_UPDATE_AREA, DUNGEON_UPDATE_FLOOR } from '@/helpers/consts';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    name: 'DungeonGridArea',
	props: {
		area: Number,
		floor: Number,
		isActive: Boolean,
		isBoss: Boolean
	},
})
export default class DungeonGridArea extends Vue
{
	updateArea(currentArea: number, currentFloor: number) {
		if (!this.getIsDud()) {
			this.$store.dispatch(DUNGEON_UPDATE_AREA, currentArea);
			this.$store.dispatch(DUNGEON_UPDATE_FLOOR, currentFloor);
		}
    }

	getIsDud()
	{
		return this.$props.area === 0;
	}
}

