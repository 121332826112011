import { DATABASE_CLEAR, DATABASE_FETCH, DATABASE_SAVE, GAME_SELECT_CHARACTER, GAME_SET_STATE, GAME_SET_DIFFICULTY, GAME_UPDATE_LANGUAGE, GAME_RESUME, SETTINGS_SET_STRICT_RULES, SETTINGS_SET_HIDDEN_INFO } from "@/helpers/consts";
import { appDB } from "@/router";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { store } from "..";
import i18n from "@/i18n";

const MutateGameState: string = 'databaseModule:MutateGameState';
const MutateSettingsState: string = 'databaseModule:MutateSettingsState';

const DB_KEY_STARTED: string = 'db.started';
const DB_KEY_LANGUAGE: string = 'db.language';
const DB_KEY_DIFFLEVEL: string = 'db.difficultyLevel';
const DB_KEY_CHAR: string = 'db.selectedCharacter';
const DB_KEY_GAMESTATE: string = 'db.gameState';
const DB_KEY_STRICTRULES: string = 'db.strictRules';
const DB_KEY_HIDDENINFO: string = 'db.hiddenInfo';

export interface DatabaseState {
}

const state: DatabaseState = {
}

const actions: ActionTree<DatabaseState, any> = {
	[DATABASE_FETCH]({ commit, state }: ActionContext<DatabaseState, any>) {
		// console.warn(`ACTION - Database fetch from storage`);
		appDB.keys().then((keys: string[]) => {
			if (keys.indexOf(DB_KEY_HIDDENINFO) > -1) {
				commit(MutateSettingsState);
			}

			if (keys.indexOf(DB_KEY_STARTED) > -1) {
				commit(MutateGameState);
			}
		});

	},
	[DATABASE_CLEAR]({ commit, state, rootState }: ActionContext<DatabaseState, any>) {
		// console.warn(`ACTION - Clear Database`);
		const hiddenInfoValue = rootState.SettingsModule.hiddenInfo;
		const strictRulesValue = rootState.SettingsModule.strictRules;
		appDB.clear();
		appDB.setItem(DB_KEY_HIDDENINFO, hiddenInfoValue);
		appDB.setItem(DB_KEY_STRICTRULES, strictRulesValue);
	},
	[DATABASE_SAVE]({ commit, state, rootState }: ActionContext<DatabaseState, any>) {
		// console.warn(`ACTION - Save to database`);
		appDB.setItem(DB_KEY_STARTED, rootState.GameModule.hasStartedGame);
		appDB.setItem(DB_KEY_LANGUAGE, rootState.GameModule.language);
		appDB.setItem(DB_KEY_DIFFLEVEL, rootState.GameModule.difficultyLevel);
		appDB.setItem(DB_KEY_CHAR, rootState.GameModule.selectedCharacter);
		appDB.setItem(DB_KEY_GAMESTATE, Object.entries(rootState.PlayerModule));
		appDB.setItem(DB_KEY_STRICTRULES, rootState.SettingsModule.strictRules);
		appDB.setItem(DB_KEY_HIDDENINFO, rootState.SettingsModule.hiddenInfo);
	}
}


const mutations: MutationTree<DatabaseState> = {
	[MutateGameState](currentState: DatabaseState) {
		// console.log("MutateGameState");

		appDB.getItem(DB_KEY_LANGUAGE).then((value: any) => {
			if (value !== null) {
				// console.log(`...get game language: ${value}`);
				store.dispatch(GAME_UPDATE_LANGUAGE, value);
				i18n.locale = value;
			}
		})


		appDB.getItem(DB_KEY_DIFFLEVEL).then((value: any) => {
			if (value !== null) {
				store.dispatch(GAME_SET_DIFFICULTY, value);
			}
		})

		appDB.getItem(DB_KEY_CHAR).then((value: any) => {
			if (value !== null) {
				store.dispatch(GAME_SELECT_CHARACTER, value);
			}
		})

		appDB.getItem(DB_KEY_GAMESTATE).then((value: any) => {
			if (value !== null) {
				// console.log("get game state");
				store.dispatch(GAME_SET_STATE, value);
			}
		})

		appDB.getItem(DB_KEY_STARTED).then((value: any) => {
			if (value !== null && value == true) {
				store.dispatch(GAME_RESUME);
			}
		})
	},
	[MutateSettingsState](currentState: DatabaseState) {
		appDB.getItem(DB_KEY_STRICTRULES).then((value: any) => {
			// console.log(`SETTINGS_SET_STRICT_RULES, ${value}`);
			if (value !== null) {
				store.dispatch(SETTINGS_SET_STRICT_RULES, value);
			}
		})
		appDB.getItem(DB_KEY_HIDDENINFO).then((value: any) => {
			// console.log(`SETTINGS_SET_HIDDEN_INFO, ${value}`);
			if (value !== null) {
				store.dispatch(SETTINGS_SET_HIDDEN_INFO, value);
			}
		})
	}
}

const getters: GetterTree<DatabaseState, any> = {
	// empty
}

export const DatabaseModule: Module<DatabaseState, any> = {
	state,
	actions,
	mutations,
	getters,
}

