










































































import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import { GAME_SELECT_LANGUAGE } from "./helpers/consts";

@Component({
  name: "Tracker",
  computed: {
	  ...mapState({
		  hasStartedGame: (state: any) => state.GameModule.hasStartedGame,
		  dungeonFloor: (state: any) => state.PlayerModule.dungeonFloor,
		  playerLevel: (state: any) => state.PlayerModule.level,
	  }),
	  ...mapGetters(["getSelectedCharacterId", "getSelectedCharacterArchetype"]),
  }
})
export default class App extends Vue {
	
	getSelectedCharacterId!: string;
	getSelectedCharacterArchetype!: string;
	hasStartedGame!: boolean;
	dungeonFloor!: number;
	playerLevel!: number;


	localeMapWithFlags: {[culture: string]: string} = {
		"de" : "🇩🇪 DE",
		"en" : "🇬🇧 EN",
		// "es" : "🇪🇸 ES",
		"fr" : "🇫🇷 FR",
	}

	sidebarOpen = false;

	onChangeLanguage(language: string)
	{
		this.$i18n.locale = `${language}`;
		this.$store.dispatch(GAME_SELECT_LANGUAGE, language);
	}
}
