import Vue from 'vue';
import Vuex from 'vuex';
import { PlayerModule } from './modules/playerModule';
import { GameModule } from './modules/gameModule';
import { DatabaseModule } from './modules/databaseModule';
import { SettingsModule } from './modules/settingsModule';

Vue.use(Vuex);
export const store = new Vuex.Store({
    modules: {
        PlayerModule,
		GameModule,
		DatabaseModule,
		SettingsModule,
    },
})