



























// @ is an alias to /src
import Vue from 'vue';

import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { Prop } from 'vue-property-decorator';

@Component({
  name: "Home",
  computed: {
	...mapState({
		hasStartedGame: (state: any) => state.GameModule.hasStartedGame,
	}),
  }
})
export default class Home extends Vue {
	hasStartedGame!: boolean;
};

