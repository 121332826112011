










































































import Vue from 'vue';
import Component from 'vue-class-component';
import Icon from '@/components/Icon.vue';
import { mapState } from 'vuex';

@Component({
    name: 'LoreSelectedChoice',
    props: {
        lore: Object,
    },
	components: {
		Icon,
	},
	computed: {
		...mapState({
            monsterHP: (state: any) => state.PlayerModule.mhp,
            playerHP: (state: any) => state.PlayerModule.hp,
            playerXP: (state: any) => state.PlayerModule.xp,
            playerLevel: (state: any) => state.PlayerModule.level,
			hiddenInfo: (state: any) => state.SettingsModule.hiddenInfo,
			playerCursed: (state: any) => state.PlayerModule.isCursed,
		}),
	}

})
export default class LoreSelectedChoice extends Vue {

	playerDiceSet: string[] = ["q", "w", "e", "r", "t", "y"];
	curseDiceSet: string[] = ["0", "0", "0", "C", "C", "C"];


	isSkillCheck = false;
	isChoice = false;
	isGameplay = false;
	isRevealed = false;
	isSkillCheckDone = false;
	isSkillCheckSuccess = false;
	isSelected = false;
	diceResults: string[] = [];
	curseDie: string;


	created()
	{
		this.isSkillCheck = this.$props.lore.type == 'skillcheck';
		this.isChoice = this.$props.lore.type == 'choice';
		this.isGameplay = this.$props.lore.type == 'gameplay';

		if (this.$store.state.PlayerModule.isCursed)
		{
			this.curseDie = this.getDiceValue(this.getRandomDiceIndex(), this.curseDiceSet);
		}
		else
		{
			this.curseDie = "0";
		}

		// @ts-ignore
		for (let i = 0; i < this.playerLevel; i++)
		{
			const newValue = this.getRandomDiceIndex();
			if (this.curseDie == "0" && newValue >= 4
			|| this.curseDie == "C" && newValue >= 5) {
				this.isSkillCheckSuccess = true;
			}
			this.diceResults.push(this.getDiceValue(newValue, this.playerDiceSet));
		}
		
	}

	getDiceValue(index: number, diceSet: string[]): string
	{
		return diceSet[index];
	}


	onClose()
	{
		// @ts-ignore
		this.$parent.close();
	}

	onSelect()
	{
		this.isSelected = true;
	}

	onRevealHiddenInfo()
	{
		this.isRevealed = true;
	}

	onSkillCheck()
	{
		this.isRevealed = true;
		this.isSkillCheckDone = true;
	}

	getRandomDiceIndex(): number
	{
		return Math.floor(Math.random() * 6);
	}


}
