















































import { DATABASE_SAVE } from "@/helpers/consts";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import CharacterMatView from "@/views/game/CharacterMatView.vue";
import DicePoolView from "@/views/game/DicePoolView.vue";
import DungeonMatView from "@/views/game/DungeonMatView.vue";
import LoreCardsView from "@/views/game/LoreCardsView.vue";
import Icon from "@/components/Icon.vue";
import { mapGetters, mapState } from "vuex";
@Component({
  name: "GameView",
  components: {
	  CharacterMatView,
	  DicePoolView,
	  DungeonMatView,
	  LoreCardsView,
	  Icon
  },
    computed: {
        ...mapState({
            monsterHP: (state: any) => state.PlayerModule.mhp,
			dungeonFloor: (state: any) => state.PlayerModule.dungeonFloor,
            playerHP: (state: any) => state.PlayerModule.hp,
            playerXP: (state: any) => state.PlayerModule.xp,
            playerAP: (state: any) => state.PlayerModule.ap,
            playerFP: (state: any) => state.PlayerModule.fp,
            playerGP: (state: any) => state.PlayerModule.gp,
            playerCursed: (state: any) => state.PlayerModule.isCursed,
            playerPoisoned: (state: any) => state.PlayerModule.isPoisoned,
            playerBlind: (state: any) => state.PlayerModule.isBlind,

            hasPotionFire: (state: any) =>
                state.PlayerModule.potions.indexOf("fire") > -1,
            hasPotionFrost: (state: any) =>
                state.PlayerModule.potions.indexOf("frost") > -1,
            hasPotionPoison: (state: any) =>
                state.PlayerModule.potions.indexOf("poison") > -1,
            hasPotionHealing: (state: any) =>
                state.PlayerModule.potions.indexOf("healing") > -1,
            hasPotionHolywater: (state: any) =>
                state.PlayerModule.potions.indexOf("holywater") > -1,
            hasPotionPerception: (state: any) =>
                state.PlayerModule.potions.indexOf("perception") > -1,

            playerLevel: (state: any) => state.PlayerModule.level,
        }),
        ...mapGetters(["getSelectedCharacterId", "getSelectedCharacterArchetype"]),

    },
})
export default class GameView extends Vue 
{
	playerXP!: number;
	playerAP!: number;
	playerHP!: number;
	playerFP!: number;
	playerGP!: number;
	playerPoisoned!: boolean;
	playerCursed!: boolean;
	playerBlind!: boolean;
	hasPotionFire!: boolean;
	hasPotionFrost!: boolean;
	hasPotionPoison!: boolean;
	hasPotionHealing!: boolean;
	hasPotionHolywater!: boolean;
	hasPotionPerception!: boolean;
	currentTab!: number;
	
	data() {
		return {
			currentTab: 0
		}
	}

	SaveGame()
	{
		this.$store.dispatch(DATABASE_SAVE);
		this.$buefy.dialog.alert('<span class="mr_heading">The game has been saved.</span>')
	}
}
