

























import Vue from "vue";
import Component from "vue-class-component";
import LoreButton from "@/components/LoreButton.vue";
import LoreSelectedChoice from "@/components/LoreSelectedChoice.vue";

@Component({
    name: "LoreChoiceView",
	components: {
		LoreButton,
		LoreSelectedChoice
	},
	props: {
		lore: Object,
    },
})
export default class LoreChoiceView extends Vue {

	onSelectChoice(loreObject: any)
	{
		this.$buefy.modal.open({
			parent: this,
			component: LoreSelectedChoice,
			trapFocus: true,
			canCancel: false,
			props: {
				lore: loreObject,
			}
		})
	}

}
