
































import { PLAYER_UPDATE_HP, PLAYER_UPDATE_XP } from '@/helpers/consts';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

@Component({
    name: 'Feat',
    props: {
        currentValue: String,
        handle: String,
    },
	computed: {
		...mapState({
            monsterHP: (state: any) => state.PlayerModule.mhp,
            playerHP: (state: any) => state.PlayerModule.hp,
            playerXP: (state: any) => state.PlayerModule.xp,
		}),
	}

})
export default class Feat extends Vue {
	playerHP: number;
	playerXP: number;
	diceValue: string = "";
	currentIndex: number = -1;
	currentDiceSet: string[] = ["q", "w", "e", "r", "t", "y"];
	isUsed: boolean = false;

	created()
	{
		this.diceValue = this.$props.currentValue;
	}

	getRandomDiceIndex(): number
	{
		return Math.floor(Math.random() * 6);
	}

	getDiceValue(): string
	{
		return this.currentDiceSet[this.currentIndex];
	}

    roll() {
		this.currentIndex = this.getRandomDiceIndex();
		this.diceValue = this.getDiceValue();
		this.$emit('on-feat-executed', {index:this.currentIndex, value:this.diceValue});
		this.isUsed = true;
    }


	onPayHP()
	{
		this.$store.dispatch(PLAYER_UPDATE_HP, -2)
		this.roll();
		this.$buefy.toast.open({
			duration: 3500,
			message: `<span class='title'>-2<span class="mr_icons hp">h</span></span>`,
			type: "is-light"
		})
		// this.onClose();
	}

	onPayXP()
	{
		this.$store.dispatch(PLAYER_UPDATE_XP, -1)
		this.roll();
		this.$buefy.toast.open({
			duration: 3500,
			message: `<span class='title'>-1<span class="mr_icons xp">(</span></span>`,
			type: "is-light"
		})
		// this.onClose();
	}

	onFreeFeat()
	{
		this.roll();
		// this.onClose();
	}

	onClose()
	{
		// @ts-ignore
		this.$parent.close();
	}



}
