























































































import Vue from 'vue';
import Component from 'vue-class-component';
import Icon from '@/components/Icon.vue';

@Component({
    name: 'ValueTracker',
    props: {
        track: String,
        value: Number,
		icon: String,
		isMonster: Boolean,
    },
	components: {
		Icon,
	}

})
export default class ValueTracker extends Vue {
    updatePlayerValue(track: string, value: number) {
        this.$store.dispatch(`playerModule:update_${track}`, value);
    }
}
