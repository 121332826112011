




































import { PLAYER_UPDATE_EFFECT } from '@/helpers/consts';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    name: 'EffectToggle',
    props: {
        effect: String,
        icon: String,
		toggled: Boolean,
    },

})
export default class EffectToggle extends Vue 
{
    updateEffectState(effect: string) {
		this.$store.dispatch(PLAYER_UPDATE_EFFECT, effect);
    }
}
