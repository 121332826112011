export const MAX_PLAYER_HP: number = 20;
export const MAX_MONSTER_HP: number = 50;
export const MAX_PLAYER_XP: number = 23;
export const MAX_PLAYER_GP: number = 10;
export const MAX_PLAYER_AP: number = 4;
export const MAX_PLAYER_FP: number = 4;

export const STARVATION_COST_HP: number = -3;
export const OVERXP_COST_HP: number = 1;

export const XP_LEVEL_1 = 0;
export const XP_LEVEL_2 = 6;
export const XP_LEVEL_3 = 18;


// ---------- G A M E ---------
export const GAME_INIT: string = "gameModule:startInit";
export const GAME_RESUME: string = "gameModule:resumeGame";
export const GAME_SET_DIFFICULTY: string = "gameModule:setDifficulty";
export const GAME_SET_STATE: string = "gameModule:setState";
export const GAME_SELECT_CHARACTER: string = "gameModule:selectCharacter";
export const GAME_SELECT_NEXT_CHARACTER: string = "gameModule:selectNextCharacter";
export const GAME_SELECT_PREV_CHARACTER: string = "gameModule:selectPrevCharacter";
export const GAME_SELECT_LANGUAGE: string = "gameModule:selectLanguage";
export const GAME_UPDATE_LANGUAGE: string = "gameModule:updateLanguage";


// ---------- P L A Y E R ---------
export const PLAYER_UPDATE_MONSTER_HP: string = 'playerModule:update_monsterHP';

export const PLAYER_CLEAR_STATE: string = 'playerModule:clearState';
export const PLAYER_UPDATE_HP: string = 'playerModule:update_playerHP';
export const PLAYER_UPDATE_XP: string = 'playerModule:update_playerXP';
export const PLAYER_UPDATE_GP: string = 'playerModule:update_playerGP';
export const PLAYER_UPDATE_FP: string = 'playerModule:update_playerFP';
export const PLAYER_UPDATE_AP: string = 'playerModule:update_playerAP';
export const PLAYER_UPDATE_STAT: string = 'playerModue:update_playerStat';

export const PLAYER_UPDATE_POTION: string = 'playerModule:update_potion';
export const PLAYER_UPDATE_EFFECT: string = 'playerModule:update_effect';

export const PLAYER_UPDATE_EFFECT_POISON: string = 'playerModule:update_effect_poison';
export const PLAYER_UPDATE_EFFECT_CURSE: string = 'playerModule:update_effect_curse';

export const DUNGEON_UPDATE_AREA: string = 'playerModule:dungeon_update_area';
export const DUNGEON_UPDATE_FLOOR: string = 'playerModule:dungeon_update_floor';


// ---------- D A T A B A S E ---------
export const DATABASE_FETCH: string = 'databaseModule:fetch';
export const DATABASE_CLEAR: string = 'databaseModule:clear';
export const DATABASE_SAVE: string = 'databaseModule:save';


// ---------- S E T T I N G S ---------
export const SETTINGS_TOGGLE_STRICT_RULES: string = "settingsModule:toggleStrictRules";
export const SETTINGS_SET_STRICT_RULES: string = "settingsModule:setStrictRules";

export const SETTINGS_TOGGLE_HIDDEN_INFO: string = "settingsModule:toggleHiddenInfo";
export const SETTINGS_SET_HIDDEN_INFO: string = "settingsModule:setHiddenInfo";