import {
	Action,
	ActionContext,
	ActionTree,
	GetterTree,
	Module,
	MutationTree
} from 'vuex';
import { clamp } from '@/helpers/utils';
import {
	MAX_PLAYER_AP,
	MAX_PLAYER_FP,
	MAX_PLAYER_GP,
	MAX_PLAYER_HP,
	MAX_PLAYER_XP,
	PLAYER_UPDATE_AP,
	PLAYER_UPDATE_FP,
	PLAYER_UPDATE_GP,
	PLAYER_UPDATE_HP,

	PLAYER_UPDATE_POTION,
	PLAYER_UPDATE_EFFECT,

	DUNGEON_UPDATE_AREA,

	PLAYER_UPDATE_XP,
	STARVATION_COST_HP,
	XP_LEVEL_2,
	XP_LEVEL_3,
	PLAYER_UPDATE_MONSTER_HP,
	GAME_SET_STATE,
	DATABASE_CLEAR,
	DUNGEON_UPDATE_FLOOR,
	DATABASE_SAVE,
	PLAYER_UPDATE_STAT,
	PLAYER_CLEAR_STATE,
	MAX_MONSTER_HP,
} from '@/helpers/consts';
import { store } from '..';
import _ from 'lodash';

const MutateMonsterHP: string = 'playerModule:mutateMonsterHP';
const MutatePlayerHP: string = 'playerModule:mutatePlayerHP';
const MutatePlayerXP: string = 'playerModule:mutatePlayerXP';
const MutatePlayerGP: string = 'playerModule:mutatePlayerGP';
const MutatePlayerFP: string = 'playerModule:mutatePlayerFP';
const MutatePlayerAP: string = 'playerModule:mutatePlayerAP';

const MutateSetPotion: string = 'playerModule:setPlayerPotion';
const MutatePlayerPotion: string = 'playerModule:mutatePlayerPotion';
const MutatePlayerEffect: string = 'playerModule:mutatePlayerEffect';

const MutateDungeonFloor: string = 'playerModule:mutateDungeonFloor';
const MutateDungeonArea: string = 'playerModule:mutateDungeonArea';
const MutateGameState: string = 'playerModule:mutateGameState';


export interface PlayerState {
	[index: string]: any;
	mhp: number,
	hp: number,
	xp: number,
	fp: number,
	ap: number,
	gp: number,
	level: number,
	isPoisoned: boolean,
	isCursed: boolean,
	isBlind: boolean,
	potions: string[],
	dungeonArea: number,
	dungeonFloor: number,
}

const state: PlayerState = {
	mhp: 0,
	hp: 0,
	xp: 0,
	fp: 0,
	ap: 0,
	gp: 0,
	level: 1,
	isPoisoned: false,
	isCursed: false,
	isBlind: false,
	potions: [],
	dungeonArea: 1,
	dungeonFloor: 1,
}

const cleanState: PlayerState = {
	mhp: 0,
	hp: 0,
	xp: 0,
	fp: 0,
	ap: 0,
	gp: 0,
	level: 1,
	isPoisoned: false,
	isCursed: false,
	isBlind: false,
	potions: [],
	dungeonArea: 1,
	dungeonFloor: 1
}

const actions: ActionTree<PlayerState, any> = {
	[PLAYER_UPDATE_MONSTER_HP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update Monster HP`);
		commit(MutateMonsterHP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_HP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update HP`);
		commit(MutatePlayerHP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_XP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update XP`);
		commit(MutatePlayerXP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_GP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update GP`);
		commit(MutatePlayerGP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_FP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update FP`);
		commit(MutatePlayerFP, value);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_AP]({ commit, state }: ActionContext<PlayerState, any>, value: number) {
		// console.warn(`ACTION - Player update AP`);
		commit(MutatePlayerAP, value);
		this.dispatch(DATABASE_SAVE);
	},

	[PLAYER_UPDATE_STAT]({ commit, state }: ActionContext<PlayerState, any>, payload: { type: string, value: string | number }) {
		let actionToUse: string = "";
		switch (payload.type) {
			case 'hp': actionToUse = PLAYER_UPDATE_HP;
				break;
			case 'xp': actionToUse = PLAYER_UPDATE_XP;
				break;
			case 'ap': actionToUse = PLAYER_UPDATE_AP;
				break;
			case 'gp': actionToUse = PLAYER_UPDATE_GP;
				break;
			case 'fp': actionToUse = PLAYER_UPDATE_FP;
				break;
			case 'potion': actionToUse = PLAYER_UPDATE_POTION;
				break;
			case 'effect': actionToUse = PLAYER_UPDATE_EFFECT;
				break;
			default: throw new Error("Wrong type of Player Stat to update.");
		}

		this.dispatch(actionToUse, payload.value);
	},

	[PLAYER_UPDATE_EFFECT]({ commit, state }: ActionContext<PlayerState, any>, effect: string) {
		// console.warn(`ACTION - Player update Effect ${effect}`);
		commit(MutatePlayerEffect, effect);
		this.dispatch(DATABASE_SAVE);
	},
	[PLAYER_UPDATE_POTION]({ commit, state }: ActionContext<PlayerState, any>, potion: string) {
		// console.warn(`ACTION - Player update Potion ${potion}`);
		commit(MutatePlayerPotion, potion);
		this.dispatch(DATABASE_SAVE);
	},
	[DUNGEON_UPDATE_AREA]({ commit, state }: ActionContext<PlayerState, any>, area: number) {
		// console.warn(`ACTION - Dungeon update area: ${area}`);
		commit(MutateDungeonArea, area);
		this.dispatch(DATABASE_SAVE);
	},
	[DUNGEON_UPDATE_FLOOR]({ commit, state }: ActionContext<PlayerState, any>, floor: number) {
		// console.warn(`ACTION - Dungeon update floor: ${floor}`);
		commit(MutateDungeonFloor, floor);
		this.dispatch(DATABASE_SAVE);
	},
	[GAME_SET_STATE]({ commit, state }: ActionContext<PlayerState, any>, data: any[]) {
		// console.warn(`ACTION - Dungeon update area: ${area}`);
		commit(MutateGameState, data);
	},
	[PLAYER_CLEAR_STATE]({ commit, state }: ActionContext<PlayerState, any>) {
		commit(MutateGameState, _.cloneDeep(Object.entries(cleanState)));
	},
	[DATABASE_CLEAR]({ commit, state }: ActionContext<PlayerState, any>) {
		commit(MutateGameState, _.cloneDeep(Object.entries(cleanState)));
	},

}

const mutations: MutationTree<PlayerState> = {
	[MutateMonsterHP](currentState: PlayerState, value: number) {
		currentState.mhp = clamp(currentState.mhp + value, 0, MAX_MONSTER_HP);
	},
	[MutatePlayerHP](currentState: PlayerState, value: number) {
		currentState.hp = clamp(currentState.hp + value, 0, MAX_PLAYER_HP);
	},
	[MutatePlayerXP](currentState: PlayerState, value: number) {
		if ((currentState.xp + value) > MAX_PLAYER_XP) {
			const excessXP: number = (currentState.xp + value) - MAX_PLAYER_XP;
			store.dispatch(PLAYER_UPDATE_HP, excessXP);
		}

		currentState.xp = clamp(currentState.xp + value, 0, MAX_PLAYER_XP);
		if (currentState.xp >= XP_LEVEL_3) {
			currentState.level = 3;
		} else if (currentState.xp >= XP_LEVEL_2) {
			currentState.level = 2;
		} else {
			currentState.level = 1;
		}
	},
	[MutatePlayerGP](currentState: PlayerState, value: number) {
		currentState.gp = clamp(currentState.gp + value, 0, MAX_PLAYER_GP);
	},
	[MutatePlayerFP](currentState: PlayerState, value: number) {
		if (currentState.fp + value < 0) {
			store.dispatch(PLAYER_UPDATE_HP, STARVATION_COST_HP);
		}
		currentState.fp = clamp(currentState.fp + value, 0, MAX_PLAYER_FP);
	},
	[MutatePlayerAP](currentState: PlayerState, value: number) {
		currentState.ap = clamp(currentState.ap + value, 0, MAX_PLAYER_AP);
	},


	[MutatePlayerEffect](currentState: PlayerState, effect: string) {
		switch (effect) {
			case 'poison': currentState.isPoisoned = !currentState.isPoisoned;
				break;
			case 'curse': currentState.isCursed = !currentState.isCursed;
				break;
			case 'blindness': currentState.isBlind = !currentState.isBlind;
				break;
		}



	},
	[MutateSetPotion](currentState: PlayerState, potion: string) {
		const potionIndex: number = currentState.potions.indexOf(potion);
		if (potionIndex == -1) {
			currentState.potions.push(potion);
		}
	},
	[MutatePlayerPotion](currentState: PlayerState, potion: string) {
		const potionIndex: number = currentState.potions.indexOf(potion);
		if (potionIndex == -1) {
			if (store.state.SettingsModule.strictRules) {
				if (currentState.potions.length < 2) {
					currentState.potions.push(potion);
				}
				else {
					console.warn("No can do, trying to add one potion too much.");
				}
			}
			else {
				currentState.potions.push(potion);
			}
		}
		else {
			currentState.potions.splice(potionIndex, 1);
		}
	},
	[MutateDungeonArea](currentState: PlayerState, area: number) {
		currentState.dungeonArea = area;
	},
	[MutateDungeonFloor](currentState: PlayerState, floor: number) {
		currentState.dungeonFloor = floor;
	},
	[MutateGameState](currentState: PlayerState, data: any[]) {
		data.forEach((dataItem: any) => {
			const [key, value] = dataItem;
			currentState[key] = value;
		})
	},


}

const getters: GetterTree<PlayerState, any> = {
	// empty
}

export const PlayerModule: Module<PlayerState, any> = {
	state,
	actions,
	mutations,
	getters,
}